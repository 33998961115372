import React, { Component } from 'react';
import '../App.css';
import $ from 'jquery';
import ReactDOM from "react-dom";
import {withTranslation} from "react-i18next";



class ProjectItemSlider extends Component {

    componentWillUpdate(nextProps) {
        if (nextProps.height !== this.props.height && this.props.height !== '100%') {
            $(ReactDOM.findDOMNode(this)).animate({
                height : nextProps.height
            });
        }
    }

    render() {
        const {image} = this.props;

        return (

            <div className="project-item-slider container-fluid" id={this.props.id} style={{backgroundImage: `url(${image})`, height: this.props.height}}>
                <div className="container"/>
            </div>

        );
    }
}

export default   withTranslation()(ProjectItemSlider);
