import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import $ from 'jquery';
import HeaderLogo from "../img/logo.svg";
import { withTranslation, Trans } from 'react-i18next';
import {Link as LinkScroll} from "react-scroll";
import {createBrowserHistory, parsePath} from 'history';

const path = parsePath(window.location.href);
const history = createBrowserHistory({ pathname: '/' + (path.pathname.indexOf('/en/') !== false ? 'en' : '') });

class Header extends Component {
        constructor(props){
            super(props);

            this.scrollOnTop = this.scrollOnTop.bind(this);

        }

    initLogo() {
        var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;
        const AdobeAn = window.AdobeAn;
        const createjs = window.createjs;

        const init = () => {
            canvas = document.getElementById("logo_canvas");
            // anim_container = document.getElementById("animation_container");
            // dom_overlay_container = document.getElementById("dom_overlay_container");
            var comp=AdobeAn.getComposition("8DD8AB0D4CD748A6877BE3C855C5A2CA");
            var lib=comp.getLibrary();
            handleComplete({},comp);
        }
        const handleComplete = (evt,comp) => {
            //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
            var lib=comp.getLibrary();
            var ss=comp.getSpriteSheet();
            exportRoot = new lib._3GMEDIALogoAnimationForWeb03();
            stage = new lib.Stage(canvas);
            //Registers the "tick" event listener.
            fnStartAnimation = function() {
                stage.addChild(exportRoot);
                createjs.Ticker.framerate = lib.properties.fps;
                createjs.Ticker.addEventListener("tick", stage);
            }
            //Code to support hidpi screens and responsive scaling.
            // AdobeAn.makeResponsive(false,'both',false,2,[canvas,anim_container,dom_overlay_container]);
            // AdobeAn.compositionLoaded(lib.properties.id);
            fnStartAnimation();
        }

        init();
    }

    componentDidMount() {

      

        var opened = 0;
        $(".navbar-toggler").click(function () {
            if (opened === 0) {
                $(".language-switcher").show();
                $(".navbar-collapse").show();
                $(".navbar-collapse").addClass("opacity-1");
                opened = 1;
                return;
            } else {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
            }
        });


        $(".main-menu-ul li").click(function () {
            if (opened === 1) {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });

        $(".navbar-brand").click(function () {
            if (opened === 1) {
                $(".language-switcher").show();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });

        this.initLogo();
    }


    vycistiMenu() {
        $("#main-menu-ul li a").removeClass("active");
    }

    showLanguages(){
        $("#dropdown-language").show();
    }

    getLocalizedRoute(lang) {
        const {i18n, t } = this.props;

        let url = new URL(window.location.href);

        let pathname = url.pathname;

        if (pathname.split('/').length > 2 && pathname.split('/')[2] !== '') {
            pathname = pathname.split('/').slice(0, (i18n.language === 'cs' ? 2 : 3)).join('/');

            return ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/') + url.pathname.replace(pathname, '');
        } else if (['/', '/en/'].includes(pathname)) {
            return (lang !== 'cs'  ? '/' + lang + '/' : '/');
        }

        return  ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/');
    }

    scrollOnTop(){
        $(window).scrollTop(0);
    }

    handleSetActive() {
        const {t} = this;

        switch (this.href) {
            case t('route.references'):

            break;
            default:
                history.push('/' + this.href);
        }
    }

    render() {
        const {i18n, t } = this.props;

        const changeLanguage = lng => {

            i18n.changeLanguage(lng);
        };

        return (
            <div>
                <header className="header">
                    <div className="container header-container">
                        <nav className="navbar navbar-expand-lg">

                            <LinkScroll className="navbar-brand" to="section-home-slider-top"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        t={t}
                                        href={(i18n.language !== 'cs'  ? '' + i18n.language + '/' : '')}
                                        onSetActive={this.handleSetActive}
                                        duration= {500}>
                                <canvas id="logo_canvas" width="200px" height="85px"></canvas>
                                <h1>PRODUCTION & DEVELOPMENT</h1>
                            </LinkScroll>

                            {/*<Link className="navbar-brand" to="" onclick={this.scrollOnTop()}>*/}
                            {/*    <img src={HeaderLogo} className="menu-logo" alt="logo"/>*/}
                            {/*    <h1>PRODUCTION & DEVELOPMENT</h1>*/}
                            {/*</Link>*/}

                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse no-transition" id="navbarSupportedContent">
                                <ul className="main-menu-ul">

                                    <li id="references-menu-li"><LinkScroll to="scrollspy-reference"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                t={t}
                                                href={t('route.references')}
                                                onSetActive={this.handleSetActive}
                                                duration= {500}> <Trans i18nKey={'header.link.references'}/></LinkScroll></li>

                                    <li id="profile-menu-li"><LinkScroll to="section-home-profile"
                                                                      spy={true}
                                                                      smooth={true}
                                                                      offset={-70}
                                                                      t={t}
                                                                      href={t('route.profile')}
                                                                      onSetActive={this.handleSetActive}
                                                                      duration= {500}> <Trans i18nKey={'header.link.profile'}/></LinkScroll></li>

                                    <li id="contact-menu-li"><LinkScroll to="section-home-contact"
                                                                         spy={true}
                                                                         smooth={true}
                                                                         offset={-70}
                                                                         t={t}
                                                                         href={t('route.contact')}
                                                                         onSetActive={this.handleSetActive}
                                                                         duration= {500}> <Trans i18nKey={'header.link.contact'}/></LinkScroll></li>




                                    <li>


                                    <div className="language-switcher ">

                                        { i18n.language !== "cs" ?  <Link  to={this.getLocalizedRoute('cs')} onClick={() => changeLanguage('cs')}>CZ</Link> : null }


                                        { i18n.language === "cs" ?  <Link className="active-lang" to={this.getLocalizedRoute('cs')} onClick={() => changeLanguage('cs')}>CZ</Link> : null }

                                        <span> | </span>
                                        { i18n.language === "en" ?  <Link className="active-lang" to={this.getLocalizedRoute('en')} onClick={() => changeLanguage('en')}>EN</Link> : null }
                                        { i18n.language !== "en" ?   <Link to={this.getLocalizedRoute('en')} onClick={() => changeLanguage('en')}>EN</Link> : null }
                                    </div>
                                    </li>
                                </ul>
                            </div>


                        </nav>
                    </div>


                </header>
            </div>
        );
    }
}

export default withTranslation()(Header);
