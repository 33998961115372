import React, { Component } from 'react';


import '../App.css';


import {withTranslation} from "react-i18next";

class Footer extends Component {
  render() {

    return (
<div>

    <footer className="footer">
        <div className="conainer">
            <object id="footer-logo"  aria-label={"footer-logo"} data="https://storage.googleapis.com/mfcc-cz/footer-logo.svg?color=fff" type="image/svg+xml"></object>
        </div>


    </footer>

</div>
    );
  }
}

export default withTranslation()(Footer);
