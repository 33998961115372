import React, {Component} from 'react';
import $ from 'jquery';
import '../App.css';


import {Trans, withTranslation} from "react-i18next";
import ArrowDownWhite from "../img/ArrowDownWhite.svg";
import {Link as LinkScroll} from "react-scroll";

class HomeSliderTop extends Component {

    constructor(props) {
        super(props);

        this.state = {
            height: '100%'
        };

        this.onWindowResize = this.onWindowResize.bind(this);
    }


    componentDidMount() {
        $(window).on('resize', this.onWindowResize).trigger('resize');
    }

    componentWillUnmount() {
        $(window).off('resize', this.onWindowResize);
    }

    onWindowResize() {
        let headerHeight = $('header.header').height();

        this.setState({height: $(window).height() - headerHeight});
    }


    render() {
        const { t, headline, years, episodes, genre, image, colorMode} = this.props;

        return (
            <div className="container-fluid" id="slider-top"
                 style={{backgroundImage: `url(${image})`, height: this.state.height}}>
                <div className="container slider-top-container">
                    <div className="slider-top-text">
                        <h1 className={colorMode === "dark" ? "black-text" : ""}>{headline}</h1>
                        <span className={colorMode === "dark" ? "black-text" : ""}>{`${years}  |  ${t('episodes', {count: parseInt(episodes)})}  |  ${genre}`}</span>

                        <LinkScroll className="navbar-brand" to="project_0"
                                    spy={true}
                                    smooth={true}
                                    offset={-250}
                                    t={t}
                                    duration= {500}>
                                    <button><Trans i18nKey={"more"} defaults={"MORE"} /> </button>
                        </LinkScroll>


                    </div>

                    <div className="slider-bottom-arrow">
                        <img alt="arrow" src={ArrowDownWhite}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeSliderTop);
