import React, { Component } from 'react';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { Route, Router} from 'react-router-dom';



import MainHome from "./Pages/MainHome";

import * as Sentry from '@sentry/browser';

import {withTranslation} from "react-i18next";


const createHistory = require("history").createBrowserHistory;



const history = createHistory();

window.routes = {};



class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            eventId: null
        };


    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId})
        });
    }

    componentWillMount() {

        const {i18n } = this.props;
        let url = new URL(window.location.href);
        switch (url.pathname.slice(0, 3)) {
            case '/en': i18n.changeLanguage('en'); break;
            default: i18n.changeLanguage('cs');
        }


        this.unlisten = history.listen((location, action) => {



        });


    }



    componentDidMount(){



    }

    ChangeValue(){

    }

  render() {
      const {i18n, t } = this.props;

      let routes = window.routes;
      routes['/'] = '';

      routes['/' + t("route.references")] = 'route.references';
      routes['/' + t("route.profile")] = 'route.profile';
      routes['/' + t("route.contact")] = 'route.contact';



      return (
        <Router history={history}>
            <div className="App">
                <Header value={this.state.value}/>
                <Route exact path={'/' + (i18n.language !== 'cs' ? i18n.language + '/' : '')}  component={MainHome} />

                <Route exact path={'/' +  t('route.references')} component={MainHome} anchor={"references"}/>

                <Route exact path={'/' +  t('route.profile')} component={MainHome} anchor={"profile"}/>

                <Route exact path={'/' +  t('route.contact')} component={MainHome} anchor={"contact"}/>


                <Footer />
            </div>
        </Router>
      );
  }
}

export default  withTranslation()(Home);
