import React, { Component } from 'react';
import '../App.css';
import ProjectBlock from "./ProjectBlock";



import {withTranslation} from "react-i18next";




class HomeSliderTop extends Component {


    componentDidMount(){

    }






  render() {


    return (
            <div>


                    <div className="container" id="project-blocks">

                        <div className="row">
                            { this.props.projects.map((project, index) => <ProjectBlock key={`projectBlock_${index}`} headline={project.title} slug={project.slug} years={project.years} episodes={parseInt(project.episodes)} genre={project.genre} id={`project-block-${index}`} image={project.image} colorMode={project.colorMode} />) }
                        </div>

                    </div>

             </div>


    );
  }
}

export default   withTranslation()(HomeSliderTop);
