import React, { Component } from 'react';
import '../App.css';



import {withTranslation, Trans} from "react-i18next";


class HomeSliderTop extends Component {

    componentDidMount(){

    }

  render() {
      const {  t } = this.props;
    return (
        <div id="contact" className="container">

            <div className="row">
                <div className="col-md-12">
                    <h1><Trans i18nKey={'home.contact.headline'}/></h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4 contact-block" >
                    <span dangerouslySetInnerHTML={{__html: t('home.contact.box1')    }}>


                        </span>
                </div>
                <div className="col-md-4 contact-block border-left-black">
                    <span>
                           <Trans i18nKey={'home.contact.box2'}/>
                        </span>
                </div>

                <div className="col-md-4 contact-block border-left-black">
                    <span>
                             <Trans i18nKey={'home.contact.box3'}/>
                        </span>
                </div>


            </div>


        </div>
    );
  }
}

export default withTranslation()(HomeSliderTop);
