import React, {Component} from 'react';
import '../App.css';
import $ from 'jquery';
// or less ideally


import {  withTranslation } from 'react-i18next';


import HomeSliderTop from "../Components/HomeSliderTop";
import ProjectBlocks from "../Components/ProjectBlocks";
import ProjectSlider from "../Components/ProjectSlider";
import Profile from "../Components/Profile";
import Contact from "../Components/Contact";
import Loader from "../img/loading.svg";



class MainHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            projects: [],
            headerProject : null
        }
    };

    componentWillMount() {
        let url = new URL(window.API + '/projects');
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({
                headerProject: data.headerProject || null,
                projects : data.projects || [],
                loaded : true
                }));
    }

    componentDidMount() {
        $(window).scrollTop(0);
        var self = this;

        var targetOffsetReference = $("#section-home-project-blocks").offset().top;
        var targetOffsetProfile = $("#section-home-profile").offset().top;
        var targetOffsetContact = $("#section-home-contact").offset().top;


        var $w = $(window).scroll(function(){
            if ( $w.scrollTop() > targetOffsetReference ) {
                    self.vycistiMenu();
                    if(!$("#references-menu-li").hasClass("active")){
                        $("#references-menu-li").addClass("active");
                    }
            }

            if ( $w.scrollTop() > targetOffsetProfile ) {
                self.vycistiMenu();
                if(!$("#profile-menu-li").hasClass("active")){
                    $("#profile-menu-li").addClass("active");
                }
            }

            if ( $w.scrollTop() > targetOffsetContact ) {
                self.vycistiMenu();
                if(!$("#contact-menu-li").hasClass("active")){
                    $("#contact-menu-li").addClass("active");
                }
            }

            if($w.scrollTop() + $w.height() === $(document).height()) {
                self.vycistiMenu();
                if(!$("#contact-menu-li a").hasClass("active")){
                    $("#contact-menu-li a").addClass("active");
                }
            }



        });
    }


    vycistiMenu(){

        $("#references-menu-li").removeClass("active");
        $("#profile-menu-li").removeClass("active");
        $("#contact-menu-li").removeClass("active");
        $("#contact-menu-li a").removeClass("active");

    }


    render() {



        return (
            <div id="home">



                {!this.state.loaded ?  <section id="loader">
                    <div className="container-fluid loader-inner">
                        <img alt="loader" src={Loader}/>
                    </div>
                </section> : null}

                <section id="section-home-slider-top">
                    { this.state.headerProject ? <HomeSliderTop
                        headline={this.state.headerProject.title}
                        years={this.state.headerProject.years}
                        episodes={parseInt(this.state.headerProject.episodes)}
                        genre={this.state.headerProject.genre}
                        colorMode={this.state.headerProject.colorMode}
                        image={this.state.headerProject.image}/> : null }
                </section>

                <div id="scrollspy-reference">
                    <section id="section-home-project-blocks">
                        <ProjectBlocks projects={this.state.projects}/>
                    </section>

                    <section id="section-home-project-sliders">
                        <div className="container">
                        {this.state.projects ? this.state.projects.map((project, index) => {
                            if (project.images) {
                                return <ProjectSlider key={`project_${index}`}
                                               id={`project_${index}`}
                                               headline={project.title}
                                               slug={project.slug}
                                               years={project.years}
                                               episodes={parseInt(project.episodes)}
                                               genre={project.genre}
                                               format={project.format}
                                               web={project.web}
                                                      links={project.links}
                                               broadcaster={project.broadcaster}
                                               productionTitle={project.production_title}
                                               production={project.production}
                                               cast={project.cast}
                                               credits={project.credits}
                                               colorMode={project.colorMode}
                                               images={project.images}/>
                            }
                        }) : null}
                        </div>
                    </section>
                </div>

                <section id="section-home-profile">
                    <Profile/>
                </section>

                <section id="section-home-contact">
                    <Contact/>
                </section>

            </div>
        );
    }
}

export default withTranslation()(MainHome);
