import React, { Component } from 'react';
import '../App.css';



import {withTranslation, Trans} from "react-i18next";


import FBIcon from "../img/facebook-icon.svg";
import INIcon from "../img/linkedin-icon.svg";
import Gabriela from "../img/gabriela.jpg";

class Profile extends Component {









  render() {


    return (
            <div id="profile" className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1><Trans i18nKey={'home.profile.headline'}/></h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 profile-block">
                        <h2><b><Trans i18nKey={'home.profile.left.headline'}/></b></h2>
                        <span>
                            <Trans i18nKey={'home.profile.left.description'}/>
                        </span>
                    </div>
                    <div className="col-md-6  profile-block border-left-white">
                        <div className="row">
                            <div className="col-md-4 profile-block-photo">

                                <img alt="Gabriela" src={Gabriela}/>

                            </div>
                            <div className="col-md-8 profile-block-text">
                                <h2><b><Trans i18nKey={'home.profile.right.headline'}/></b><Trans i18nKey={'home.profile.right.headline2'}/></h2>
                                <span className="producent-span"><Trans i18nKey={'home.profile.right.headline3'}/></span>
                                <span>
                                    <Trans i18nKey={'home.profile.right.description'}/>
                                </span>
                                <div className="profile-block-links">
                                    <a href="https://www.facebook.com/gabriela.erleova"  rel="noopener noreferrer" target="_blank"><img src={FBIcon} alt="fb icon"/>Facebook</a>&nbsp;&nbsp;/&nbsp;&nbsp;<a href="https://www.linkedin.com/in/gabriela-erleov%C3%A1-752896149/"  rel="noopener noreferrer" target="_blank"><img alt="In icon" src={INIcon}/>LinkedIn</a>
                                </div>
                            </div>

                        </div>





                    </div>
                </div>


            </div>
    );
  }
}

export default   withTranslation()(Profile);
