import React, { Component } from 'react';
import '../App.css';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Slider from "react-slick";
import {withTranslation, Trans} from "react-i18next";
import ProjectItemSlider from "../Components/ProjectItemSlider";

import $ from "jquery";
import ReactDOM from "react-dom";


class ProjectSlider extends Component {
    constructor(props){
        super(props);

        this.state = {
            opened : false,
            height: '100%',
            openHeight: 'auto',
            closedHeight: 'auto'
        };

        this.onWindowResize = this.onWindowResize.bind(this);
        this.showDetail = this.showDetail.bind(this);
    }

    componentDidMount(){
        this.setState({ opened : false });

        // $(window).on('resize', this.onWindowResize).trigger('resize');
    }

    componentWillUnmount() {
        // $(window).off('resize', this.onWindowResize);
    }

    onWindowResize() {
        let blockHeight = $(window).height() - $('header.header').height();
        let openHeight = $(ReactDOM.findDOMNode(this)).find('.project-item-slider-bottom').height('auto').height();
        let closedHeight = $(ReactDOM.findDOMNode(this)).find("#" + this.props.id + "-block-1").height();

        this.setState({
            height: blockHeight,
            openHeight : openHeight,
            closedHeight : closedHeight
        });

        if (!this.state.opened) {
            $(ReactDOM.findDOMNode(this)).find('.project-item-slider-bottom').height(closedHeight);
        } else {
            $(ReactDOM.findDOMNode(this)).find('.project-item-slider-bottom').height(closedHeight);
        }

        if ($(window).width() >= 768) {
            $(ReactDOM.findDOMNode(this)).find('.project-slider-background').css('height', closedHeight + 100);
        } else {
            $(ReactDOM.findDOMNode(this)).find('.project-slider-background').removeAttr('style');
        }
    }

    showDetail(){
        if(!this.state.opened){
            $("#project-slider-background-" + this.props.id).removeClass("ComeDownBGAnimClass");
            $("#project-slider-background-" + this.props.id).addClass("ComeUpBGAnimClass");

            $("#" + this.props.id + "-block-2").removeClass("animBlock2Back");
            $("#" + this.props.id + "-block-3").removeClass("animBlock2Back");


            $("#" + this.props.id + "-block-2").addClass("animBlock1");
            $("#" + this.props.id + "-block-3").addClass("animBlock2");

            $("#project-slider-more-btn" + this.props.id ).text(this.props.t("LESS"));
            $(ReactDOM.findDOMNode(this)).find('.project-item-slider-bottom').animate({
                height: this.state.openHeight
            });

            this.setState({ opened : true });

        } else {

            $("#project-slider-background-" + this.props.id).removeClass("ComeUpBGAnimClass");
            $("#project-slider-background-" + this.props.id).addClass("ComeDownBGAnimClass");

            $("#" + this.props.id + "-block-2").removeClass("animBlock1");
            $("#" + this.props.id + "-block-3").removeClass("animBlock2");



            $("#" + this.props.id + "-block-2").addClass("animBlock2Back");

            $("#" + this.props.id + "-block-3").addClass("animBlock2Back");


            $("#project-slider-more-btn" + this.props.id ).text(this.props.t("MORE"));
            $(ReactDOM.findDOMNode(this)).find('.project-item-slider-bottom').animate({
                height: this.state.closedHeight
            });

            this.setState({ opened : false });

        }
    }



  render() {
      const { i18n, t, headline,  id, links, slug, years, episodes, genre, format, broadcaster, production, productionTitle, cast, credits, images, colorMode } = this.props;

      var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: true,
      };

      let headlineHeight = $(ReactDOM.findDOMNode(this)).find('.project-slider-text-top').outerHeight(true);
      let height = this.state.opened && this.state.openHeight + headlineHeight + 50 > this.state.height ? this.state.openHeight + headlineHeight + 50 : this.state.height;

      return (
        <div className="project-slider-full">

                <div className=" project-slider-text-top">

                    <div className="row">
                        <div className="col-md-8 project-item-slider-headline left" id={id + "-project-item-slider-headline"}>


                            <div className="topper">
                                <h1 className={ colorMode === "dark" ? "black-text" : "" }>{headline}</h1>
                                <span className={ colorMode === "dark" ? "black-text" : "" }>{`${years}  |  ${t('episodes', { count: episodes })}  |  ${genre}`}</span>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="project-slider" id={id} data-href={(i18n.language !== 'cs' ? '/' + i18n.language : '') + '/' + slug}>
                    { images ? <Slider {...settings}>
                        {images.map((image, index) => <ProjectItemSlider key={`ProjectItemSlider_${index}`} image={image} id={`project-item-slider-${index}`} height={height} />)}
                    </Slider> : null }







                    {/*<div className="project-slider-background" id={ "project-slider-background-" + id} />*/}


                </div>

            <div className=" project-slider-text-bottom">
                <div className="row project-item-slider-bottom">

                    <div className={`  ${ cast.cs.length === 0 && credits.cs.length === 0 ? "col-md-12" : "col-md-4" } project-item-slider-block ${ colorMode === "dark" ? "black-text" : "" }`} id={id + "-block-1"}>

                        <div className="topper">
                            {format ? [<strong><Trans i18nKey={"format"} defaults={"FORMAT"} /> / </strong>, format, <br />] : null}
                            {broadcaster ? [<strong><Trans i18nKey={"broadcaster"} defaults={"BROADCASTER"} /> / </strong>, broadcaster, <br />] : null}
                            {production ? [<strong>{productionTitle[i18n.language] ? productionTitle[i18n.language] : <Trans i18nKey={"production"} defaults={"PRODUCTION"} />} / </strong>, production, <br />] : null}
                            {/*{cast && credits ?*/}
                            {/*    <button className="project-slider-more-btn" onClick={this.showDetail} id={"project-slider-more-btn" + id}><Trans i18nKey={'project-slider.more.btn'}/></button>*/}
                            {/*    : null}*/}

                            { links ? <div>
                                {links.map((item, index) => [<strong><Trans i18nKey={"link"} defaults={"WEBSITE"} /> / </strong>, <a href={item.url}  rel="noopener noreferrer" target="_blank">{item.title[i18n.language]}</a>, <br />] )}
                            </div> : null }


                        </div>
                    </div>



                    {cast.cs.length > 0 ?
                        <div className={`col-md-4  project-item-slider-block  ${ colorMode === "dark" ? "black-text border-left-black" : "border-left-white" }`} id={id + "-block-2"}>

                            <div className="topper">
                                {cast[i18n.language] ? [<strong><Trans i18nKey={"cast"} defaults={"CAST"} /> / </strong>, cast[i18n.language]] : null}
                            </div>
                        </div>
                        : null}

                    {credits.cs.length > 0 ?
                        <div className={`col-md-4  project-item-slider-block  ${ colorMode === "dark" ? "black-text border-left-black" : "border-left-white" }`} id={id + "-block-3"}>

                            <div className="topper">
                                {credits[i18n.language] ? [<strong><Trans i18nKey={"credits"} defaults={"CREDITS"} /> / </strong>, credits[i18n.language]] : null}
                            </div>
                        </div>
                        : null}
                </div>

            </div>
        </div>
    );
  }
}

export default   withTranslation()(ProjectSlider);
