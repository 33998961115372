import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import { unregister } from './registerServiceWorker';
import * as Sentry from '@sentry/browser';

import './i18n';


window.API = process.env.REACT_APP_API_URL;

Sentry.init({
    dsn: "https://0cc43d7f50124a768a545fe1a066146a@sentry.io/1483622",
    environment : process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production'
});



const Loader = () => (
    <div className="loaderMain">Loading</div>
);

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <Home />
    </Suspense>,
    document.getElementById('root'));
unregister();