import React, { Component } from 'react';
import '../App.css';

import {Link as LinkScroll} from "react-scroll";

import {withTranslation, Trans} from "react-i18next";





class HomeSliderTop extends Component {

    constructor(props){
        super(props);

        this.state = {scrollToElement: ""};
    }
    componentDidMount(){



        var str = this.props.id;
        var res = str.replace("-block-", "_");


        this.setState({
            scrollToElement: res
        });
    }

  render() {
    const { t, headline, years, episodes, genre, image, colorMode } = this.props;

    return (
        <div className={`col-lg-4 col-md-6 project-block project-block--${colorMode}`}>
            <div className="project-block-inner">

                <div className="project-block-background"  style={{backgroundImage: `url(${image})`}}>

                </div>

                <div className="row">
                    <div className="col-md-10 project-block-text">
                        <div className={ colorMode === "dark" ? "white-bg" : "black-bg" }></div>
                        <div className="topper">
                            <h2 className={ colorMode === "dark" ? "black" : "" }>{headline}</h2>
                            <span className={ colorMode === "dark"  ? "black" : "" }>{`${years} | ${t('episodes', {count:episodes})} | ${genre}`}</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12  project-block-more-btn">
                        <LinkScroll to={this.state.scrollToElement} spy={true} smooth={true} offset={-250} duration={500}>
                            <button className={ colorMode === "dark" ? "black-btn black" : "" }><Trans i18nKey={'home.project-block.more-btn'}/></button>
                        </LinkScroll>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default   withTranslation()(HomeSliderTop);
